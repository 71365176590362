<template>
  <V-card class="mx-auto my-16" max-width="600">
    <v-card-title> Venda Número #123 </v-card-title>
    <v-card-text>
      <v-btn block color="primary" @click="createPayment()"> Pagar </v-btn>
    </v-card-text>
  </V-card>
</template>

<script>
export default {

  methods : {
    createPayment() {
      this.$http.store('mercado-pago/preference')
        .then(response => {
          window.location.href = response.preference.init_point
        })
    }
  }
};
</script>

<style>
</style>